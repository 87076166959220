<template>
  <main class="kb-main" id="kb-myclass-hrdcloud">
    <lxp-main-header :show-title="true" :show-breadcrumb="true">
      <template v-slot:action>
        <!-- main-header > header-tab -->
        <div class="header-tab">
          <nav class="kb-nav-round-tabs">
            <ul class="kb-nav-list">
              <li class="kb-nav-item">
                <a href="javascript:" class="kb-nav-link" :class="{'is-active': reserveVenue === '연수원'}" @click="reserveVenue = '연수원'"><span class="kb-nav-text">연수원</span></a>
              </li>
              <li class="kb-nav-item">
                <a href="javascript:" class="kb-nav-link" :class="{'is-active': reserveVenue === '체육시설'}" @click="reserveVenue = '체육시설'"><span class="kb-nav-text">체육시설</span></a>
              </li>
<!--              <li class="kb-nav-item">
                <a href="javascript:" class="kb-nav-link" :class="{'is-active': reserveVenue === '스튜디오'}" @click="reserveVenue = '스튜디오'"><span class="kb-nav-text">스튜디오</span></a>
              </li>-->
            </ul>
          </nav>
        </div>
      </template>
    </lxp-main-header>
    <!-- main-content -->
    <div class="main-content">
      <!-- apply-list-container -->
      <div class="apply-list-container">
        <!-- apply-list-container > list-filter -->
        <div class="list-filter">
          <div class="filter-wrapper min-component">
            <!-- filter-item(시설명 선택) -->
            <div class="filter-item">
              <div v-if="reserveVenue == '연수원'" data-id="dropdown-filter" class="dropdown" :class="{'is-active': toggles.trnctOpen }" @click="toggles.trnctOpen = !toggles.trnctOpen">
                <button class="dropdown-btn kb-btn kb-btn-white"><span class="text">{{ toggles.label }}</span><i class="icon-arrow"></i></button>
                <div class="dropdown-target">
                  <div class="dropdown-box">
                    <ul class="dropdown-option-list">
                      <li class="dropdown-option-item" v-for="item in trnctMstList" :key="item.trnctMstSn">
                        <a @click="clickTrnctMstSn(item); toggles.trnctOpen = !toggles.trnctOpen" href="javascript:" class="dropdown-option-link"><span class="dropdown-option-text">{{ item.trnctNm }}</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div v-if="reserveVenue == '체육시설'" data-id="dropdown-filter" class="dropdown" :class="{'is-active': toggles.fcltOpen }" @click="toggles.fcltOpen = !toggles.fcltOpen">
                <button class="dropdown-btn kb-btn kb-btn-white"><span class="text">{{ toggles.label }}</span><i class="icon-arrow"></i></button>
                <div class="dropdown-target">
                  <div class="dropdown-box">
                    <ul class="dropdown-option-list">
                      <li class="dropdown-option-item">
                        <a @click="clickFclt(); toggles.fcltOpen = !toggles.fcltOpen" href="javascript:" class="dropdown-option-link"><span class="dropdown-option-text">일산 운동장</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- filter-item(예약일시 선택) -->
            <div class="filter-item">
              <div data-id="dropdown-calendar-picker" style="display: flex;justify-content: space-around;" class="dropdown" :class="{'is-active': showCalendar }" @click="showCalendar = !showCalendar">
                <kb-datepicker-range v-model="schedule" ref="kbDatePicker"/>
              </div>
            </div>
            <!-- filter-buttons -->
            <div class="filter-buttons">
              <button v-if="reserveVenue == '연수원'" class="kb-btn kb-btn-primary" type="button" @click="getTrcntAply"><span class="text">검색</span></button>
              <button v-if="reserveVenue == '체육시설'" class="kb-btn kb-btn-primary" type="button" @click="getFcltAply"><span class="text">검색</span></button>
              <button @click="initSearch" class="kb-btn kb-btn-white" type="button"><span class="text">초기화</span></button>
            </div>
          </div>
        </div>
        <!-- //apply-list-container > list-filter -->
        <!-- apply-list-container > list-wrapper -->
        <div class="list-wrapper min-component" v-show="reserveVenue === '연수원'">
          <!-- list-top -->
          <div class="list-top pb-4">
            <div class="top-column">
              <p class="title text-muted">전체 {{ paging.totalCount }}건</p>
            </div>
          </div>
          <!-- //list-top -->
          <!-- status-tablelist -->
          <div class="apply-tablelist">
            <div class="kb-table kb-table-bordered">
              <table>
                <colgroup>
                  <col style="width:60px;">
                  <col style="width:150px;">
<!--                  <col style="width:160px;">-->
                  <col style="width:auto;">
                  <col style="width:250px;">
                  <col style="width:150px;">
                  <col style="width:280px;">
                </colgroup>
                <thead>
                <tr>
                  <th><span class="th-title">구분</span></th>
                  <th><span class="th-title">시설명</span></th>
<!--                  <th><span class="th-title">상세</span></th>-->
                  <th><span class="th-title">이용기간</span></th>
                  <th><span class="th-title">신청일시</span></th>
                  <th><span class="th-title">신청상태</span></th>
                  <th class="text-center"><span class="th-title">기능</span></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item) in items" :key="item.trnctAplySn">
                  <td><span class="td-text">연수원</span></td>
                  <td><strong class="td-text">{{ item.trnctNm ? item.trnctNm : item.trnctMstSn == 1 ? "속초연수원" : "대천연수원" }}</strong></td>
<!--                  <td><span class="td-text"></span></td>-->
                  <td><span class="td-text">{{ item.rsvtAplyDd }} ~ {{ item.rsvtAplyDdEnd }}</span></td>
                  <td><span class="td-text">{{ item.regDt }}</span></td>
<!--                  <td><strong class="td-text text-yellow">{{ item.rsvtStt }}</strong></td>-->
                  <td><strong class="td-text text-yellow">{{ item.stbyYn == 'Y' ? `대기(순번 : ${item.stbySeq + 2})` : '신청완료'}}</strong></td>
                  <td>
                    <div class="td-actions" v-if="checkThreeDay(item)">
                      <button class="kb-btn kb-btn-outline rounded-lg" type="button" @click="detailTrcntAply(item)"><strong class="text">상세ㆍ수정</strong></button>
                      <button class="kb-btn kb-btn-outline rounded-lg" type="button" @click="openCancelPop(item)"><strong class="text">취소</strong></button>
                    </div>
                    <div v-else-if="new Date() > new Date(item.rsvtAplyDd) " class="td-actions">
                      <span>-</span>
                    </div>
                    <div v-else class="td-actions">
                      <button class="kb-btn kb-btn-outline rounded-lg" type="button" @click="alertThreeday"><strong class="text">상세ㆍ수정</strong></button>
                      <button class="kb-btn kb-btn-outline rounded-lg" type="button" @click="alertThreeday"><strong class="text">취소</strong></button>
                    </div>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- //status-tablelist -->
          <!-- pagination -->

          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
          <!-- //pagination -->
        </div>
        <!-- //apply-list-container > list-wrapper -->
        <div class="list-wrapper min-component" v-show="reserveVenue === '체육시설'">
          <!-- list-top -->
          <div class="list-top pb-4">
            <div class="top-column">
              <p class="title text-muted">전체 {{ paging2.totalCount }}건</p>
            </div>
          </div>
          <!-- //list-top -->
          <!-- status-tablelist -->
          <div class="apply-tablelist">
            <div class="kb-table kb-table-bordered">
              <table>
                <colgroup>
                  <col style="width:60px;">
                  <col style="width:150px;">
<!--                  <col style="width:160px;">-->
                  <col style="width:auto;">
                  <col style="width:250px;">
                  <col style="width:150px;">
                  <col style="width:280px;">
                </colgroup>
                <thead>
                <tr>
                  <th><span class="th-title">구분</span></th>
                  <th><span class="th-title">시설명</span></th>
<!--                  <th><span class="th-title">상세</span></th>-->
                  <th><span class="th-title">신청기간</span></th>
                  <th><span class="th-title">신청일시</span></th>
                  <th><span class="th-title">신청상태</span></th>
                  <th class="text-center"><span class="th-title">기능</span></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item) in items2" :key="item.fcltRsvtAplySn">
                  <td><span class="td-text">체육시설</span></td>
                  <td><strong class="td-text">{{ item.fcltDivNm }}</strong></td>
<!--                  <td><span class="td-text"></span></td>-->
                  <td><span class="td-text">{{ item.aplyYmd }} / {{ getSportTime(item)}} </span></td>
                  <td><span class="td-text">{{ item.regDt }}</span></td>
                  <td><strong class="td-text text-yellow">{{ item.allocSttYn == 'Y' ? '배정완료' : '신청중' }}</strong></td>
                  <td>
                    <div class="td-actions">
                      <button class="kb-btn kb-btn-outline rounded-lg" type="button" @click="detailFcltRsvtAply(item)"><strong class="text">상세</strong></button>
                      <button class="kb-btn kb-btn-outline rounded-lg" type="button" @click="cancelTrnctAply(item)"><strong class="text">취소</strong></button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- //status-tablelist -->
          <!-- pagination -->

          <CommonPaginationFront :paging="paging2" :page-func="pagingFunc2"/>
          <!-- //pagination -->
        </div>
      </div>
      <!-- //apply-list-container -->
    </div>
    <!-- //main-content -->

    <!-- begin:popup-container 개인팝업 -->
    <div class="popup-container" :class="{'is-active': data.isPersonalPop}">
      <!-- popup -->
      <div class="popup" id="popup-facility-k08">
        <!-- popup > popup-inner -->
        <div class="popup-inner">
          <form action="">
            <!-- popup-inner > popup-header -->
            <header class="popup-header">
              <h3 class="title">연수원 개인 신청내역</h3>
            </header>
            <!-- popup-inner > popup-content -->
            <div class="popup-content">
              <div class="facility-resve">
                <!-- facility-resve-section -->
                <div class="facility-resve-section">
                  <div class="kb-table kb-table-striped">
                    <table>
                      <colgroup>
                        <col />
                      </colgroup>
                      <thead>
                      <tr>
                        <th><span class="th-title">구분</span></th>
                        <th><span class="th-title">신청자 정보</span></th>
                        <th><span class="th-title">연수원</span></th>
                        <th><span class="th-title">입소일</span></th>
                        <th><span class="th-title">퇴소일</span></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><span class="td-text">{{ updateTrnctAply.prsnBrcYn == 'Y' ? '개인' : '부점' }}</span></td>
                        <td><span class="td-text">{{ updateTrnctAply.accDeptNm }} ・ {{ updateTrnctAply.aplcntJbgd }} ・ {{ updateTrnctAply.aplcntNm }}</span></td>
                        <td><span class="td-text">{{ updateTrnctAply.trnctNm }}</span></td>
                        <td><span class="td-text">{{ updateTrnctAply.rsvtAplyDd }}  ({{ getDayOfWeek(updateTrnctAply.rsvtAplyDd) }})</span></td>
                        <td><span class="td-text">{{ updateTrnctAply.rsvtAplyDdEnd }}  ({{ getDayOfWeek(updateTrnctAply.rsvtAplyDdEnd) }})</span></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- facility-resve-section -->
                <div class="facility-resve-section section-divider">
                  <div class="kb-form-fields">
                    <!-- kb-form-row:숙실 형태 -->
                    <div class="kb-form-row kb-form-field-period">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">숙실 형태</span>
                        </label>
                      </div>
                      <div class="kb-form-column flex-fill">
                        <div class="kb-form-check-group">
                          <div class="kb-form-check kb-form-check-inline">
                            <input type="radio" disabled v-model="updateTrnctAply.roomTy" class="kb-form-check-input" id="chk_group01_01" name="chk_group01" value="OR" />
                            <label for="chk_group01_01" class="kb-form-check-label"><span class="text">원룸</span></label>
                          </div>
                          <div class="kb-form-check kb-form-check-inline">
                            <input type="radio" disabled v-model="updateTrnctAply.roomTy" class="kb-form-check-input" id="chk_group01_02" name="chk_group01" value="TR" />
                            <label for="chk_group01_02" class="kb-form-check-label"><span class="text">투룸</span></label>
                          </div>
                          <div class="kb-form-check kb-form-check-inline">
                            <input type="radio" disabled v-model="updateTrnctAply.roomTy" class="kb-form-check-input" id="chk_group01_03" name="chk_group01" value="LTR" />
                            <label for="chk_group01_03" class="kb-form-check-label"><span class="text">안식년 (투룸)</span></label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label" style="display: flex;align-items: center;">
                          <span class="kb-form-label-text">입소일</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <div style="display: flex;align-items: center;">
                          <kb-calender v-model="schedule" :model-value="schedule" :trnct-mst-sn="updateTrnctAply.trnctMstSn" :room-ty="updateTrnctAply.roomTy" v-on:update:modelValue="changeTrnctDt"></kb-calender>
                        </div>
                      </div>
                    </div>

                    <!-- kb-form-row:이용기간 선택 -->
                    <div class="kb-form-row kb-form-field-period">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">이용기간 선택</span>
                        </label>
                      </div>
                      <div class="kb-form-column flex-fill">
                        <div class="kb-form-dropdown" :class="{'is-active': toggles.periodOpen}">
                          <button type="button" class="kb-form-dropdown-btn" @click="openPeriod"><span class="text">숙박날짜</span><i class="icon-arrow"></i></button>
                          <div class="kb-form-dropdown-options">
                            <div class="dropdown-option">
                              <ul class="dropdown-option-list">
                                <li class="dropdown-option-item" v-for="item in usePeridOption" :key="item.value">
                                  <a href="javascript:" class="dropdown-option-link" @click="selectUsePeriod(item)">
                                    <span class="dropdown-option-text">{{ item.label }}</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="kb-form-column">
                        <span>{{data.display}}</span>
                      </div>
                    </div>
                    <!-- kb-form-row:비상 연락처 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">비상 연락처</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input type="tel" v-model.trim="updateTrnctAply.emerCt" class="kb-form-control" placeholder="비상 연락처를 입력하세요." />
                      </div>
                    </div>
                    <!-- kb-form-row:차량번호 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">차량번호</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input type="text" v-model="updateTrnctAply.carNo" class="kb-form-control" placeholder="차량번호를 입력하세요." />
                      </div>
                    </div>
                    <!-- kb-form-row:입소자 명단 1 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">입소자 명단 1</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input type="text" v-model="updateTrnctAply.aplyDtl[0].cpnnNm" class="kb-form-control" placeholder="이름을 입력하세요" />
                      </div>
                    </div>
                    <!-- kb-form-row:입소자 명단 2 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">입소자 명단 2</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input type="text" v-model="updateTrnctAply.aplyDtl[1].cpnnNm" class="kb-form-control" placeholder="이름을 입력하세요" />
                      </div>
                    </div>
                    <!-- kb-form-row:입소자 명단 3 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">입소자 명단 3</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input type="text" v-model="updateTrnctAply.aplyDtl[2].cpnnNm" class="kb-form-control" placeholder="이름을 입력하세요" />
                      </div>
                    </div>
                    <!-- kb-form-row:입소자 명단 4 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">입소자 명단 4</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input type="text" v-model="updateTrnctAply.aplyDtl[3].cpnnNm" class="kb-form-control" placeholder="이름을 입력하세요" />
                      </div>
                    </div>
                    <!-- kb-form-row:입소자 명단 5 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">입소자 명단 5</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input type="text" v-model="updateTrnctAply.aplyDtl[4].cpnnNm" class="kb-form-control" placeholder="이름을 입력하세요" />
                      </div>
                    </div>
                    <!-- kb-form-row:이용 인원 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">이용 인원</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <p class="kb-form-text">{{ updateTrnctAply.aplyPeopl }}명</p>
                      </div>
                    </div>
                    <!-- kb-form-row:특이사항 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">특이사항</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <textarea class="kb-form-control" v-model="updateTrnctAply.dmndCn" placeholder="특이사항을 입력하세요"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- popup-inner > popup-buttons -->
            <div class="popup-buttons">
              <button class="kb-btn kb-btn-outline kb-btn-lg" type="button" @click="closePop('personal')"><span class="text">닫기</span></button>
              <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="setUpdateTrnctAply"><span class="text">수정</span></a>
            </div>
          </form>
        </div>
        <!-- popup > popup-close -->
        <div class="popup-close">
          <button class="kb-btn-popup-close" type="button" @click="data.isPersonalPop = false"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
      <!-- //popup -->
    </div>
    <!-- end:popup-container -->

    <!-- begin:popup-container 부점팝업 -->
    <div class="popup-container" :class="{'is-active': data.isStorePop}">
      <!-- popup -->
      <div class="popup" id="popup-facility-k07">
        <!-- popup > popup-inner -->
        <div class="popup-inner">
          <form action="">
            <!-- popup-inner > popup-header -->
            <header class="popup-header">
              <h3 class="title">연수원 부점 신청내역</h3>
            </header>
            <!-- popup-inner > popup-content -->
            <div class="popup-content">
              <div class="facility-resve">
                <!-- facility-resve-section -->
                <div class="facility-resve-section">
                  <div class="kb-table kb-table-striped">
                    <table>
                      <colgroup>
                        <col />
                      </colgroup>
                      <thead>
                      <tr>
                        <th><span class="th-title">구분</span></th>
                        <th><span class="th-title">신청자 정보</span></th>
                        <th><span class="th-title">연수원</span></th>
                        <th><span class="th-title">입소일</span></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><span class="td-text">{{ updateTrnctAply.prsnBrcYn == 'Y' ? '개인' : '부점' }}</span></td>
                        <td><span class="td-text">{{ updateTrnctAply.accDeptNm }} ・ {{ updateTrnctAply.aplcntJbgd }} ・ {{ updateTrnctAply.aplcntNm }}</span></td>
                        <td><span class="td-text">{{ updateTrnctAply.trnctNm }}</span></td>
                        <td><span class="td-text">{{ updateTrnctAply.rsvtAplyDd }}  ({{ getDayOfWeek(updateTrnctAply.rsvtAplyDd) }})</span></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- facility-resve-section -->
                <div class="facility-resve-section section-divider">
                  <div class="kb-form-fields">
                    <!-- kb-form-row:입소인원 선택 -->
                    <div class="kb-form-row kb-form-field-np">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">입소인원 선택</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <label class="kb-form-label kb-form-column-title">
                          <span class="kb-form-label-text">남자</span>
                        </label>
                      </div>
                      <div class="kb-form-column" style="display: flex;align-items: baseline;">
                        <input  v-model="updateTrnctAply.mlePeopl" type="text" class="kb-form-control" placeholder="" /><span>명</span>
                      </div>
                      <div class="kb-form-column">
                        <label class="kb-form-label kb-form-column-title">
                          <span class="kb-form-label-text">여자</span>
                        </label>
                      </div>
                      <div class="kb-form-column" style="display: flex;align-items: baseline;">
                        <input  v-model="updateTrnctAply.femPeopl" type="text" class="kb-form-control" placeholder="" /><span>명</span>
                      </div>
                      <div class="kb-form-column justify-end">
                        <span class="kb-form-text kb-form-text-sm text-muted">총 인원 6명</span>
                      </div>t
                    </div>
                    <!-- kb-form-row:신청 숙실 수 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">신청 숙실 수</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input v-model="updateTrnctAply.useRoomCnt" type="number" class="kb-form-control" placeholder="신청 숙실 수를 입력하세요." />
                      </div>
                    </div>
                    <!-- kb-form-row:이용기간 선택 -->
                    <div class="kb-form-row kb-form-field-period">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">이용기간 선택</span>
                        </label>
                      </div>
                      <div class="kb-form-column flex-fill">
                        <div class="kb-form-dropdown" :class="{'is-active': toggles.periodOpen}">
                          <button type="button" class="kb-form-dropdown-btn" @click="openPeriod"><span class="text">숙박날짜</span><i class="icon-arrow"></i></button>
                          <div class="kb-form-dropdown-options">
                            <div class="dropdown-option">
                              <ul class="dropdown-option-list">
                                <li class="dropdown-option-item" v-for="item in usePeridOption" :key="item.value">
                                  <a href="javascript:" class="dropdown-option-link" @click="selectUsePeriod(item)">
                                    <span class="dropdown-option-text">{{ item.label }}</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="kb-form-column">
                        <span>{{data.display}}</span>
                      </div>
                    </div>
                    <!-- kb-form-row:서무직원번호 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">서무직원번호</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input v-model="updateTrnctAply.mblTelno" type="tel" class="kb-form-control" placeholder="직원번호를 입력하세요." />
                      </div>
                    </div>
                    <!-- kb-form-row:비상연락처 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">비상연락처</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input v-model.trim="updateTrnctAply.emerCt" type="tel" class="kb-form-control" placeholder="비상 연락처를 입력하세요." />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- facility-resve-section -->
                <div class="facility-resve-section section-divider">
                  <div class="segment-box textarea-box">
                    <p class="textarea-title">신청사유</p>
                    <textarea v-model="updateTrnctAply.aplyRsn" rows="10" class="kb-form-control" placeholder="신청사유를 입력해 주세요.">기업지원부문의 워크샵을 위해 신청합니다.</textarea>
                  </div>
                  <div class="segment-box textarea-box mt-3">
                    <p class="textarea-title">특이사항</p>
                    <textarea v-model="updateTrnctAply.mngrMemoCn" rows="10" class="kb-form-control" placeholder="특이사항을 입력해 주세요.">비흡연자가 많으니 담배냄새는 절대 사절입니다.</textarea>
                  </div>
                </div>
              </div>
            </div>
            <!-- popup-inner > popup-buttons -->
            <div class="popup-buttons">
              <button class="kb-btn kb-btn-outline kb-btn-lg" type="button" @click="closePop('store')"><span class="text">닫기</span></button>
              <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="setUpdateTrnctAply"><span class="text">수정</span></a>
            </div>
          </form>
        </div>
        <!-- popup > popup-close -->
        <div class="popup-close">
          <button class="kb-btn-popup-close" type="button" @click="data.isStorePop = false"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
      <!-- //popup -->
    </div>
    <!-- end:popup-container -->

    <!-- begin:popup-container 신청취소-->
    <div class="popup-container" :class="{'is-active': data.isCancelPop}">
      <!-- popup -->
      <div class="popup">
        <!-- popup > popup-inner -->
        <div class="popup-inner">
          <form action="">
            <!-- popup-inner > popup-header -->
            <header class="popup-header">
              <h3 class="title">신청 취소</h3>
            </header>
            <!-- popup-inner > popup-content -->
            <div class="popup-content">
              <div class="facility-resve">
                <!-- facility-resve-section -->
                <div class="facility-resve-section">
                  <div class="segment-box textarea-box">
                    <p class="textarea-title">취소사유</p>
                    <textarea v-model="updateTrnctAply.cancelDesc" rows="10" class="kb-form-control" placeholder="취소사유를 입력해 주세요."></textarea>
                  </div>
                  <div class="segment-box-bottom">
                    <p class="text-icon text text-muted">입소 3영업일 전까지는 취소가 가능하며 이후 취소시에는 연수원 담당자 앞 개별 요청 해주셔야 합니다.</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- popup-inner > popup-buttons -->
            <div class="popup-buttons">
              <button type="button" class="kb-btn kb-btn-outline kb-btn-lg" @click="closePop('cancel')"><span class="text">닫기</span></button>
              <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="cancelTrnctAply"><span class="text">취소 신청</span></a>
            </div>
          </form>
        </div>
        <!-- popup > popup-close -->
        <div class="popup-close">
          <button class="kb-btn-popup-close" type="button" @click="closePop('cancel')"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
      <!-- //popup -->
    </div>
    <!-- end:popup-container -->

    <!-- [begin::alert-container] -->
    <div class="kb-alert-container" :class="{'is-active': data.isCancelConfirmPop}">
      <div class="kb-alert">
        <div class="kb-alert-contents pt-5">
          <strong class="kb-alert-title">신청취소</strong>
          <p class="kb-alert-text text-center">신청이 정상 취소되었습니다. 당해 연도 입소일 기준 연수원 별 3 회 취소 시 1회 이용으로 간주 됩니다. <br/>감사합니다.</p>
        </div>
        <div class="kb-alert-actions">
          <button class="kb-btn-alert" type="button" @click="data.isCancelConfirmPop = false"><span class="text">확인</span></button>
        </div>
      </div>
    </div>
    <!-- [end::alert-container] -->


    <!-- begin:popup-container 시설 사용-->
    <div class="popup-container" :class="{'is-active': data.isSportPop}">
      <!-- popup -->
      <div class="popup">
        <!-- popup > popup-inner -->
        <div class="popup-inner">
          <form action="">
            <!-- popup-inner > popup-header -->
            <header class="popup-header">
              <h3 class="title">시설 사용 신청내역</h3>
            </header>
            <!-- popup-inner > popup-content -->
            <div class="popup-content">
              <div class="facility-resve">
                <!-- facility-resve-section -->
                <div class="facility-resve-section">
                  <div class="kb-table kb-table-striped">
                    <table>
                      <colgroup>
                        <col />
                      </colgroup>
                      <thead>
                      <tr>
                        <th><span class="th-title">체육시설</span></th>
                        <th><span class="th-title">이용일시</span></th>
                        <th><span class="th-title">신청일시</span></th>
                        <th><span class="th-title">최종수정일시</span></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><span class="td-text">일산 운동장</span></td>
                        <td><span class="td-text">{{ updateFcltRsvtAply.aplyYmd }} ・ {{ getSportTime(updateFcltRsvtAply) }}</span></td>
                        <td><span class="td-text">{{ updateFcltRsvtAply.regDt }}</span></td>
                        <td><span class="td-text">{{ updateFcltRsvtAply.mdfcnDt }}</span></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- facility-resve-section -->
                <div class="facility-resve-section section-divider">
                  <div class="kb-form-fields">
                    <!-- kb-form-row:신청자 1 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">신청자 1</span>
                        </label>
                      </div>
                      <div class="kb-form-column d-flex align-items-center">
                        <strong class="kb-form-text">{{ updateFcltRsvtAply.aplcntDeptNm }} ・ {{ updateFcltRsvtAply.aplcntNm }} | {{ updateFcltRsvtAply.aplcntId }} </strong>
                      </div>
                    </div>
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">신청자 2</span>
                        </label>
                      </div>
                      <div class="kb-form-column column-whole kb-form-search">
                        <input @keyup.enter="searchUser" v-model="userSearchParam.searchText" type="text" class="kb-form-control" placeholder="직번을 입력하세요." />
                        <button type="button" class="kb-form-search-btn" @click="searchUser"><i class="icon-search"></i></button>
                      </div>
                    </div>

                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">신청자 2 정보</span>
                        </label>
                      </div>
                      <div class="kb-form-column d-flex align-items-center">
                        <strong class="kb-form-text">{{ updateFcltRsvtAply.aplcntDeptNm2 }} ・ {{ updateFcltRsvtAply.aplcntNm2 }} | {{ updateFcltRsvtAply.aplcntId2 }} </strong>
                      </div>
                    </div>
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">신청자 2 생년월일</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input type="text" maxlength="6" v-model="updateFcltRsvtAply.brdt2" class="kb-form-control w-312"
                        placeholder="생년월일 6자리를 입력하세요." />
                      </div>
                    </div>  


                    <!-- kb-form-row:이용인원 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">이용인원</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input v-model="updateFcltRsvtAply.aplyPeopl" type="number" class="kb-form-control" placeholder="이용인원을 입력하세요." />
                      </div>
                    </div>
                    <!-- kb-form-row:사무실전화번호 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">신청자 1 연락처</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input  maxlength="12" v-model.trim="updateFcltRsvtAply.aplcntTelno" type="text" class="kb-form-control" placeholder="신청자 1 연락처 입력해주세요." />
                      </div>
                    </div>
                    <!-- kb-form-row:휴대폰전화번호 -->
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">신청자 2 연락처</span>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <input  maxlength="12" v-model.trim="updateFcltRsvtAply.aplcntTelno2" type="text" class="kb-form-control" placeholder="신청자 2 연락처 입력해주세요." />
                      </div>
                    </div>
                  
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <span class="kb-form-label-text">부점/단체명</span>
                        </label>
                      </div>
                      <div class="kb-form-column w-288">
                        <div class="kb-form-dropdown" :class="{'is-active': toggles.grpToggle}">
                          <button type="button" @click="openGrpToggle" class="kb-form-dropdown-btn"><span class="text">{{ updateFcltRsvtAply.grpDivNm == null ? '단체구분' : updateFcltRsvtAply.grpDivNm }}</span><i class="icon-arrow"></i></button>
                          <div class="kb-form-dropdown-options">
                            <div class="dropdown-option">
                              <ul class="dropdown-option-list">
                                <li class="dropdown-option-item" v-for="item in codeList" :key="item.cdSn">
                                  <a @click="selectGrp(item)" href="javascript:" class="dropdown-option-link"><span class="dropdown-option-text">{{ item.cdNm }}</span></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="kb-form-column w-288" v-show="toggles.clubToggle==false || toggles.grpOrClub == true">
                        <input type="text" class="kb-form-control" v-model="updateFcltRsvtAply.grpNm" placeholder="" @click="openClubToggle" />
                      </div>                
                      
                      <div class="kb-form-column w-288" v-show="toggles.clubToggle==true && toggles.grpOrClub == false">
                        <div class="kb-form-dropdown is-active">
                          <button type="button" @click="openClubToggle" class="kb-form-dropdown-btn"><span class="text">둥호회 선택</span><i
                              class="icon-arrow"></i></button>
                          <div class="kb-form-dropdown-options">
                            <div class="dropdown-option">
                              <ul class="dropdown-option-list">
                                <li class="dropdown-option-item" v-for="item in clubList" :key="item.code">
                                  <a @click="selectClub(item)" href="javascript:" class="dropdown-option-link"><span
                                      class="dropdown-option-text">{{ item.label }}</span></a>
                                </li>
                              </ul>
                            </div>
                          </div>                      
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="kb-form-fields-bottom">
                    <div class="text-notice"><strong class="text">허위일 경우 향후 6개월간 이용이 제한됩니다</strong></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- popup-inner > popup-buttons -->
            <div class="popup-buttons">
              <button type="button" @click="cancelFcltRsvtAplyPop" class="kb-btn kb-btn-outline kb-btn-lg"><span class="text">취소</span></button>
              <a @click="fnBeforeUpdate" href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg"><span class="text">수정</span></a>
            </div>
          </form>
        </div>
        <!-- popup > popup-close -->
        <div class="popup-close">
          <button @click="cancelFcltRsvtAplyPop" class="kb-btn-popup-close" type="button"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
      <!-- //popup -->
    </div>
    <!-- end:popup-container -->


  </main>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {ref, reactive, onMounted, watch,computed} from 'vue';
import {
  initPaging,
  lengthCheck,
  getItems,
  getPaging,
  setParamsByQueryString,
  initProgressBar,
  isSuccess,
  isTelFormat,
  isHpTelFormat,
  dateToDateFormat, getPreviousBusinessDay
} from "@/assets/js/util";
import {useStore} from "vuex";
import {
  ACT_CANCEL_FCLT_RSVT_APLY,
  ACT_CANCEL_TRNCT_PLAY, ACT_GET_FCLT_APLY,
  ACT_GET_TRNCT_APLY, ACT_GET_TRNCT_MST_LIST,
  ACT_GET_TRNCT_OPER_YMD_CHECK, ACT_UPDATE_FCLT_RSVT_APLY,
  ACT_UPDATE_TRNCT_APLY,
  ACT_GET_USER_LIST,
} from "@/store/modules/trnct/trnct";
import {useRoute, useRouter} from 'vue-router';
import CommonPaginationFront from "@/components/CommonPaginationFront";
import {useAlert} from "@/assets/js/modules/common/alert";
import KbDatepickerRange from "@/components/common/KbDatepickerRange";
import KbCalender from "@/components/common/KbCalender";
export default {
  name: 'DailyApplyHistory',
  components: {KbCalender, KbDatepickerRange,  CommonPaginationFront, LxpMainHeader},
  setup() {

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showMessage,showConfirm} = useAlert();
  
    const reserveVenue = ref('연수원');
    const showVenues = ref(false);
    const showCalendar = ref(false);
    const filterVenue = ref('');
    const filterDate = ref('');
    const userList = ref([]);
    const kbDatePicker = ref(null);

    const codeList = computed(() =>{
      const array = [];
      array.push({ cd: '', cdNm: '단체구분' });
      array.push(...store.state.code.codes.filter(code => code.upCd === '2095000' && code.cdNm === '당행 동호회'));
      //debugger // eslint-disable-line
      return  array;
    });

    const paging = ref(initPaging(route));
    const items = ref([{}]);

    const paging2 = ref(initPaging(route));
    const items2 = ref([{}]);

    const trnctMstList = ref([]);

    const schedule = ref({
      startDt: '',
      endDt: ''
    });

    const fnValidation=(svc)=>{
      
      if("updateFcltRsvtAply"==svc){

        //신청마감이후, 마감처리 이후 수정불가
        if(updateFcltRsvtAply.rcptEndDt<=dateToDateFormat(new Date(),"yyyy-MM-dd, hh:mm")){
          showMessage('신청마감일 이후 수정하실 수 없습니다.');
          return;
        }

        //마감처리 이후 수정불가
        if(updateFcltRsvtAply.operYn!="Y"){
          showMessage('마감된 운영차수는 수정하실 수 없습니다.');
          return;
        }

        if (updateFcltRsvtAply.aplcntId2 == null || updateFcltRsvtAply.aplcntId2 == '' ) {
          showMessage('신청자2를 선택해주세요.');
          return;
        }

        if (updateFcltRsvtAply.aplyPeopl == null || updateFcltRsvtAply.aplyPeopl == '' || updateFcltRsvtAply.aplyPeopl == 0) {
          showMessage('이용인원을 입력해주세요');
          return false;
        }
        /*
        if (updateFcltRsvtAply.grpDivCdDcd == null || updateFcltRsvtAply.grpDivCdDcd == '') {
          showMessage('단체를 선택해주세요');
          return false;
        }*/

        if (updateFcltRsvtAply.aplcntTelno == null || updateFcltRsvtAply.aplcntTelno == '') {
          showMessage('신청자 1 연락처를 입력해주세요');
          return false;
        }

        if(!isTelFormat(updateFcltRsvtAply.aplcntTelno) && !isHpTelFormat(updateFcltRsvtAply.aplcntTelno) ){
          showMessage('신청자 1 연락처가 잘못되었습니다.');
          return false;          
        }        


        if (updateFcltRsvtAply.aplcntTelno2 == null || updateFcltRsvtAply.aplcntTelno2 == '') {
          showMessage('신청자 2 연락처를 입력해주세요');
          return false;
        }

        if(!isTelFormat(updateFcltRsvtAply.aplcntTelno2) && !isHpTelFormat(updateFcltRsvtAply.aplcntTelno2) ){
          showMessage('신청자 2 연락처가 잘못되었습니다.');
          return false;          
        }                

        if (updateFcltRsvtAply.aplcntTelno == updateFcltRsvtAply.aplcntTelno2) {
          showMessage('신청자 1, 신청자 2 연락처를 동일하게 입력할 수 없습니다.');
          return false;
        }        

        if (updateFcltRsvtAply.grpNm == null || updateFcltRsvtAply.grpNm == '') {
          showMessage('단체명을 입력해주세요.');
          return false;
        }
      }else if("updateTrnctAply"==svc){
        
        if (updateTrnctAply.emerCt == "" || updateTrnctAply.emerCt == null) {
          showMessage('비상연락처를 입력해주세요');
          return false;
        }

        if(!isTelFormat(updateTrnctAply.emerCt) && !isHpTelFormat(updateTrnctAply.emerCt) ){
           showMessage('비상연락처가 잘못되었습니다.');
          return false;          
        }            
      }
      return true;
  }

  const fnBeforeUpdate = async () => {
        if(!fnValidation("updateFcltRsvtAply")) return;
        setUpdateFcltRsvtAply();

    }

  const clubList = ref([]);
 

    
    const getTrnctMstList = () => {
      store.dispatch(`trnct/${ACT_GET_TRNCT_MST_LIST}`).then(res => {
        if (lengthCheck(res)) {
          trnctMstList.value = res.items;
        }
      }).catch((e) => {
        console.error(e);
      })
    }

    const search = reactive({
      displayName : '시설명 선택', //연수원 명
      //prsnBrcYn: null, //개인 부점 구분 Y는 개인 N은 부점
      trnctMstSn : 0,
      fcltDivCdDcd : null,
      rsvtAplyDdStart: null, //예약 신청일
      rsvtAplyDdEnd: null,
    });

    const data = reactive({
      isPersonalPop : false,
      isStorePop : false,
      isCancelPop : false,
      isCancelConfirmPop: false,
      isSportPop: false,
      display : null
    })

    const usePeridOption = ref([]);

    const toggles = reactive({
      periodOpen: false,
      trnctOpen: false,
      label : '시설물 선택'
    });

    const selectUsePeriod = (usePeriod) => {

      if(usePeriod.value == 0){
        return;
      }

      if(schedule.value.startDt.ymdformat == updateTrnctAply.tempRsvtAplyDd){
        if(usePeriod.value > updateTrnctAply.tempUsePerid){
          showMessage('일정을 변경할 수 없습니다.');
          return;
        }
      }

      toggles.periodOpen = false;
      updateTrnctAply.usePerid = usePeriod.value;
      updateTrnctAply.aplySecretKey = usePeriod.aplySecretKey;
      data.display = usePeriod.label;
    }

    const openPeriod = () => {
      if(toggles.periodOpen === true){
        toggles.periodOpen = false;
      } else{
        toggles.periodOpen = true;
      }
    }



    const updateTrnctAply = reactive({
      aplySecretKey: null,
      trnctAplySn : null,
      prsnBrcYn : null,
      aplcntId : null,
      aplcntJbgd: null,
      aplcntNm: null,
      accDeptNm: null,
      rsvtAplyDd: null,
      rsvtAplyDdEnd: null,
      trnctNm: null,
      roomTy: null,
      emerCt: null,
      carNo : null,
      mblTelno: null,
      useRoomCnt : null,
      mlePeopl: null,
      femPeopl: null,
      usePerid: null,
      tempUsePerid:null,
      aplyRsn: null,
      dmndCn: null,
      aplyDtl : [
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        }
      ],
      cancelDesc: null,
      stbyYn : 'N',
      tempRsvtAplyDd : null
    })


    const updateFcltRsvtAply = reactive({
      fcltRsvtAplySn : null,
      aplyYmd : null,
      aplcntId : null,
      aplcntNm : null,
      aplcntDeptNm : null,
      aplcntId2 : null,
      aplcntNm2 : null,
      aplcntDeptCd2 : null,
      aplcntDeptNm2 : null,
      grpNm : null,
      aplyPeopl : null,
      part01RsvtYn : null,
      part02RsvtYn : null,
      part03RsvtYn : null,
      part04RsvtYn : null,
      part05RsvtYn : null,
      regDt : null,
      mdfcnDt : null,
      brdt : null,
      brdt2 : null,
      aplcntTelno : null,
      aplcntTelno2 :null,
      grpDivCdDcd : null,
      grpDivNm : null,
      operYn : null,
      rcptBgngDt : null,
      rcptEndDt : null
    });


    const getTrcntAply = () => {
      //console.log('연수원 검색')
      //debugger // eslint-disable-line
      if( schedule.value.startDt?.ymdformat == null ||  schedule.value.startDt?.ymdformat==undefined
           ||  schedule.value.endDt?.ymdformat == null ||  schedule.value.endDt?.ymdformat==undefined
      ){        
          
        schedule.value.startDt = '';
        schedule.value.endDt = '';
      }

      store.dispatch(`trnct/${ACT_GET_TRNCT_APLY}`, {
        trnctMstSn : search.trnctMstSn,
        rsvtAplyDdStart: schedule.value.startDt?.ymdformat,
        rsvtAplyDdEnd: schedule.value.endDt?.ymdformat,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize,
      }).then(res => {
        //console.log("연수원 검색완료");
        if (lengthCheck(res)) {
          //console.log(res);
          items.value = getItems(res);
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const getFcltAply = () => {
      //console.log('체육시설 검색');
      
      store.dispatch(`trnct/${ACT_GET_FCLT_APLY}`, {
        rsvtAplyDdStart: search.rsvtAplyDdStart,
        rsvtAplyDdEnd: search.rsvtAplyDdEnd,
        pageNo: paging2.value.pageNo,
        pageSize: paging2.value.pageSize,
      }).then(res => {
        console.log("체육시설 검색완료");
        console.log(res);
        if (lengthCheck(res)) {
          console.log(res);
          items2.value = getItems(res);
          paging2.value = getPaging(res);
        } else {
          items2.value = [];
          paging2.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      router.push({query: query});
    };

    const pagingFunc2 = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging2.value.pageSize) {
        query.pageSize = pageSize;
      }
      router.push({query: query});
    };

    watch(() => route.query, () => {
      //console.log(route.name);
      if(reserveVenue.value === '연수원'){
        setParamsByQueryString(route, 'DailyHistory', paging, search, true);
        getTrcntAply();
      }else{
        setParamsByQueryString(route, 'DailyHistory', paging2, search, true);
        getFcltAply();
      }

      initProgressBar();
    });

    watch(() => reserveVenue.value, () => {
      console.log('reserveVenue', reserveVenue.value)
      if(reserveVenue.value === "연수원"){
        pagingFunc(1,10);
        getTrcntAply();
      }else if(reserveVenue.value === "체육시설"){
        pagingFunc2(1,10);
        getFcltAply();
      }
    });


    onMounted(() => {
      getTrcntAply()
      getTrnctMstList();
      clubList.value.push({ label: "레전드", code: "레전드" });
      clubList.value.push({ label: "본축회", code: "본축회" });
      clubList.value.push({ label: "일레븐", code: "일레븐" });
      clubList.value.push({ label: "중우회", code: "중우회" });
      clubList.value.push({ label: "까치회", code: "까치회" });
      clubList.value.push({ label: "기타", code: "기타" });    

    });

    const closePop = (pop) => {
      clearParams();
      if(pop == 'personal'){
        data.isPersonalPop = false;
      }else if(pop == 'personal'){
        data.isStorePop = false;
      }else{
        updateTrnctAply.cancelDesc = null;
        data.isCancelPop = false;
      }
    }

    const detailTrcntAply = (item) => {
      updateTrnctAply.trnctAplySn = item.trnctAplySn;
      updateTrnctAply.prsnBrcYn = item.prsnBrcYn;
      updateTrnctAply.aplcntId = item.aplcntId;
      updateTrnctAply.aplcntJbgd= item.aplcntJbgd;
      updateTrnctAply.aplcntNm= item.aplcntNm;
      updateTrnctAply.accDeptNm= item.accDeptNm;
      updateTrnctAply.rsvtAplyDd= item.rsvtAplyDd;
      schedule.value.startDt = '';
      //debugger // eslint-disable-line
      updateTrnctAply.tempRsvtAplyDd= item.rsvtAplyDd;
      updateTrnctAply.trnctNm= item.trnctNm;
      updateTrnctAply.roomTy= item.roomTy;
      updateTrnctAply.emerCt= item.emerCt;
      updateTrnctAply.carNo = item.carNo;
      updateTrnctAply.mblTelno= item.mblTelno;
      updateTrnctAply.useRoomCnt = item.useRoomCnt;
      updateTrnctAply.mlePeopl= item.mlePeopl;
      updateTrnctAply.femPeopl= item.femPeopl;
      updateTrnctAply.usePerid= item.usePerid;
      updateTrnctAply.tempUsePerid = item.usePerid;
      updateTrnctAply.aplyRsn= item.aplyRsn;
      updateTrnctAply.dmndCn= item.dmndCn;
      updateTrnctAply.trnctMstSn = item.trnctMstSn;
      updateTrnctAply.rsvtAplyDdEnd = item.rsvtAplyDdEnd;
      updateTrnctAply.stbyYn = item.stbyYn;
  

      for(let i = 0 ; i < item.aplyDtl.length ;i++){
        updateTrnctAply.aplyDtl[i].cpnnNm = item.aplyDtl[i].cpnnNm;
        updateTrnctAply.aplyDtl[i].aplcntRel = item.aplyDtl[i].aplcntRel;
      }
      if(item.prsnBrcYn == 'Y'){ //개인
        data.isPersonalPop = true;
      }else{
        data.isStorePop = true;
      }

      getTrnctOperYmdCheck(item);
    }

    const userSearchParam = reactive( {
      searchText : null,
      totalCount : 0
    });

    const openClubToggle = () => {
      console.log("###########openClubToggle#");
      if (toggles.clubToggle === true) {
        toggles.clubToggle = false;
      } else {
        toggles.clubToggle = true;
      }
    }



    const detailFcltRsvtAply = (item) => {
      toggles.clubToggle=false;
      data.isSportPop = true;
      userSearchParam.searchText = null;
      updateFcltRsvtAply.fcltRsvtAplySn = item.fcltRsvtAplySn;
      updateFcltRsvtAply.aplyYmd = item.aplyYmd;
      updateFcltRsvtAply.aplcntId = item.aplcntId;
      updateFcltRsvtAply.aplcntNm = item.aplcntNm;
      updateFcltRsvtAply.aplcntDeptNm = item.aplcntDeptNm;
      updateFcltRsvtAply.aplcntId2 = item.aplcntId2;
      updateFcltRsvtAply.aplcntNm2 = item.aplcntNm2;
      updateFcltRsvtAply.aplcntDeptNm2 = item.aplcntDeptNm2;
      updateFcltRsvtAply.grpDivCdDcd = item.grpDivCdDcd;
      updateFcltRsvtAply.grpDivNm = item.grpDivNm;
      updateFcltRsvtAply.grpNm = item.grpNm;
      updateFcltRsvtAply.aplyPeopl = item.aplyPeopl;
      updateFcltRsvtAply.part01RsvtYn = item.part01RsvtYn;
      updateFcltRsvtAply.part02RsvtYn = item.part02RsvtYn;
      updateFcltRsvtAply.part03RsvtYn = item.part03RsvtYn;
      updateFcltRsvtAply.part04RsvtYn = item.part04RsvtYn;
      updateFcltRsvtAply.part05RsvtYn = item.part05RsvtYn;
      updateFcltRsvtAply.regDt = item.regDt;
      updateFcltRsvtAply.mdfcnDt = item.mdfcnDt;
      updateFcltRsvtAply.aplcntTelno = item.aplcntTelno;
      updateFcltRsvtAply.aplcntTelno2 = item.aplcntTelno2;
      updateFcltRsvtAply.brdt = item.brdt;
      updateFcltRsvtAply.brdt2 = item.brdt2;

      updateFcltRsvtAply.operYn = item.operYn;
      updateFcltRsvtAply.rcptBgngDt = item.rcptBgngDt;
      updateFcltRsvtAply.rcptEndDt = item.rcptEndDt;

    }

    const cancelFcltRsvtAplyPop = () =>{
      data.isSportPop = false;
    }


    const openCancelPop = (item) => {
      //showMessage('신청기간이 마감되어 수정 및 삭제가 불가능합니다. 연수관리자에게 문의바랍니다.');
      //2주동안 임시로 수정기능을 막는다
      updateTrnctAply.trnctAplySn = item.trnctAplySn;
      updateTrnctAply.stbyYn = item.stbyYn;
      data.isCancelPop = true;
    }

    const alertThreeday = () =>{
      showMessage('3영업일 이내 수정/취소 하실 수 없습니다.');
      return;
    }
    const cancelTrnctAply = async (item) => {
      showConfirm({
          text: '취소 하시겠습니까?',
          callback: async () => {      
            if(reserveVenue.value === '연수원'){
              await store.dispatch(`trnct/${ACT_CANCEL_TRNCT_PLAY}`, updateTrnctAply).then(res => {
                if (isSuccess(res)) {
                  data.isCancelPop = false;
                  data.isCancelConfirmPop = true;
                  updateTrnctAply.cancelDesc = null;
                  getTrcntAply();
                }
              }).catch((e) => {console.error(e);})
            }else if(reserveVenue.value === '체육시설'){

              //신청마감이후, 마감처리 이후 수정불가
              if(item.rcptEndDt<=dateToDateFormat(new Date(),"yyyy-MM-dd, hh:mm")){
                showMessage('신청마감일 이후 취소하실 수 없습니다. 담당자에게 문의하시기 바랍니다.');
                return;
              }

              //마감처리 이후 수정불가
              if(item.operYn!="Y"){
                showMessage('마감처리된 차수는 취소하실 수 없습니다. 담당자에게 문의하시기 바랍니다.');
                return;
              }

              if(item.fcltRsvtAplySn != undefined){
                updateFcltRsvtAply.fcltRsvtAplySn = item.fcltRsvtAplySn;
              }

              await store.dispatch(`trnct/${ACT_CANCEL_FCLT_RSVT_APLY}`, updateFcltRsvtAply).then(res => {
                if (isSuccess(res)) {
                  data.isSportPop = false;
                  data.isCancelPop = false;
                  data.isCancelConfirmPop = true;
                  getFcltAply();
                }
              }).catch((e) => {console.error(e);})
           }
          }
        });


    }

    const getTrnctOperYmdCheck = async (item) => {
      //debugger // eslint-disable-line
      usePeridOption.value = [];
      await store.dispatch(`trnct/${ACT_GET_TRNCT_OPER_YMD_CHECK}`,{
        trnctMstSn : item.trnctMstSn,
        rsvtAplyDd : item.rsvtAplyDd,
        prsnBrcYn : item.prsnBrcYn,
        roomTy : item.roomTy
      }).then(res => {
        if(lengthCheck(res)){
          //console.log('getTrnctOperYmdCheck...',res);
          usePeridOption.value = [];
          for(let i = 0 ; i < res.items.length ;i++){
            usePeridOption.value.push(res.items[i]);
          }


          let selectOption = usePeridOption.value.filter( (v) => {
            return v.value == item.usePerid
          })

          if(selectOption.length != 0){
            data.display = selectOption[0].label;
          }
        }
      }).catch((e) => {console.error(e);})
    }


    

    const setUpdateTrnctAply = async() => {
      if(!fnValidation("updateTrnctAply")) return;


      if(updateTrnctAply.prsnBrcYn == 'N'){

        if(updateTrnctAply.mlePeopl == null || updateTrnctAply.mlePeopl == ''){
          updateTrnctAply.mlePeopl = 0;
        }

        if(updateTrnctAply.femPeopl == null || updateTrnctAply.femPeopl == ''){
          updateTrnctAply.femPeopl = 0;
        }
        updateTrnctAply.aplyPeopl = parseInt(updateTrnctAply.mlePeopl) + parseInt(updateTrnctAply.femPeopl);
      }

     // updateTrnctAply.rsvtAplyDd = schedule.value.startDt.ymdformat;
     showConfirm({
          text: '수정 하시겠습니까?',
          callback: async () => {
            await store.dispatch(`trnct/${ACT_UPDATE_TRNCT_APLY}`, updateTrnctAply).then(res => {
              if (isSuccess(res)) {
                clearParams();
                data.isPersonalPop = false;
                data.isStorePop = false;
                showMessage('수정 되었습니다.');
                getTrcntAply();
              } else {
                showMessage(`아래 사유로 인하여 수정하실 수 없습니다.<br>사유를 확인하시고 재시도 하시기 바랍니다.<br/><br/>사유:${res.errResultCode}`);
              }
            }).catch((e) => {console.error(e);})
          }
        });
   }

    const setUpdateFcltRsvtAply = async () => {
      showConfirm({
        text: '수정 하시겠습니까?',
        callback: async () => {            
          await store.dispatch(`trnct/${ACT_UPDATE_FCLT_RSVT_APLY}`, updateFcltRsvtAply).then(res => {
            if (isSuccess(res)) {
              data.isSportPop = false;
              getFcltAply();
            }
          }).catch((e) => {console.error(e);})
        }
      });
    }

  const searchUser = () => {

    if(userSearchParam.searchText == null){
      showMessage('직번을 입력해주세요.');
      return;
    }

    if (userSearchParam.searchText == updateFcltRsvtAply.aplcntId) {
        showMessage('신청자1과 다른 직번을 입력해주십시오.');
        userSearchParam.searchText = '';
        return;
    }          

    console.log('유저 검색 버튼 ')
    store.dispatch(`trnct/${ACT_GET_USER_LIST}`, {
      lrnerId: userSearchParam.searchText,
      pageNo: 1,
      pageSize: 100
    }).then(res=>{
      if(lengthCheck(res)){
        userList.value = getItems(res);
        console.log(userList.value)

        updateFcltRsvtAply.aplcntId2 = userList.value[0].lrnerId;
        updateFcltRsvtAply.aplcntNm2 = userList.value[0].lrnerNm;
        updateFcltRsvtAply.aplcntDeptCd2 = userList.value[0].deptCd;
        updateFcltRsvtAply.aplcntDeptNm2 = userList.value[0].deptNm;

      }else{
        showMessage('해당 직번이 없습니다.');
        userList.value = [];
        userSearchParam.totalCount = 0;

        updateFcltRsvtAply.aplcntId2 = null;
        updateFcltRsvtAply.aplcntNm2 = null;
        updateFcltRsvtAply.aplcntDeptCd2 = null;
        updateFcltRsvtAply.aplcntDeptNm2 = null;
      }
    }).catch(e=>{
      console.error(e);
    });
}

  watch(() => updateFcltRsvtAply.aplcntTelno, () => {
    updateFcltRsvtAply.aplcntTelno = (updateFcltRsvtAply.aplcntTelno==null? "": updateFcltRsvtAply.aplcntTelno).replace(/[^0-9]/g, '')
  })

  watch(() => updateFcltRsvtAply.aplcntTelno2, () => {      
    updateFcltRsvtAply.aplcntTelno2 = (updateFcltRsvtAply.aplcntTelno2==null? "": updateFcltRsvtAply.aplcntTelno2).replace(/[^0-9]/g, '')        
  })

  watch(() => updateFcltRsvtAply.brdt, () => {      
    updateFcltRsvtAply.brdt = (updateFcltRsvtAply.brdt==null? "": updateFcltRsvtAply.brdt).replace(/[^0-9]/g, '')        
  })    

  watch(() => updateFcltRsvtAply.brdt2, () => {      
    updateFcltRsvtAply.brdt2 = (updateFcltRsvtAply.brdt2==null? "": updateFcltRsvtAply.brdt2).replace(/[^0-9]/g, '')        
  })    

    const getDayOfWeek = (_date) => {
      const week = ['일', '월', '화', '수', '목', '금', '토'];
      const dayOfWeek = week[new Date(_date).getDay()];
      return dayOfWeek;
    }

    const getSportTime = (item) => {

      if(item.part01RsvtYn == 'Y'){
        return "08:00 ~ 10:00 (1회차)"
      }else if(item.part02RsvtYn == 'Y'){
        return "10:00 ~ 12:00 (2회차)"
      }else if(item.part03RsvtYn == 'Y'){
        return "12:00 ~ 14:00 (3회차)"
      }else if(item.part04RsvtYn == 'Y'){
        return "14:00 ~ 16:00 (4회차)"
      }else if(item.part05RsvtYn == 'Y'){
        return "16:00 ~ 18:00 (5회차)"
      }


    }

    

    watch(updateTrnctAply.aplyDtl, () => {
      updateTrnctAply.aplyPeopl = updateTrnctAply.aplyDtl.filter( (v)=> {return ( v.cpnnNm != null && v.cpnnNm != '') } ).length
    });

    watch(() => updateTrnctAply.roomTy, () => {
      /*toggles.periodOpen = false;
      data.display = null;
      updateTrnctAply.usePerid = null;
      if(data.isPersonalPop == true )
      getTrnctOperYmdCheck(updateTrnctAply);*/
    })
    
    const clearParams = () => {
          updateTrnctAply.trnctAplySn = null;
          updateTrnctAply.prsnBrcYn = null;
          updateTrnctAply.aplcntId = null;
          updateTrnctAply.aplcntJbgd= null;
          updateTrnctAply.aplcntNm= null;
          updateTrnctAply.accDeptNm= null;
          updateTrnctAply.rsvtAplyDd= null;
          updateTrnctAply.trnctNm= null;
          updateTrnctAply.roomTy= null;
          updateTrnctAply.emerCt= null;
          updateTrnctAply.carNo = null;
          updateTrnctAply.mblTelno= null;
          updateTrnctAply.useRoomCnt = null;
          updateTrnctAply.mlePeopl= null;
          updateTrnctAply.femPeopl= null;
          updateTrnctAply.usePerid= null;
          updateTrnctAply.aplyRsn= null;
          updateTrnctAply.dmndCn= null;
          updateTrnctAply.stby='N';
          updateTrnctAply.aplyDtl = [
            {
              aplcntRel : null, //신청자 관계
              cpnnNm : null //동반자 명
            },
            {
              aplcntRel : null, //신청자 관계
              cpnnNm : null //동반자 명
            },
            {
              aplcntRel : null, //신청자 관계
              cpnnNm : null //동반자 명
            },
            {
              aplcntRel : null, //신청자 관계
              cpnnNm : null //동반자 명
            },
            {
              aplcntRel : null, //신청자 관계
              cpnnNm : null //동반자 명
            }
          ]
    }

    const checkThreeDay = (item) => {

      if(item.rsvtAplyDd != undefined){
        const masTime = new Date(item.rsvtAplyDd);

        // 당일포함 3영업일이므로 days는 2로 지정
        let limitDay = getPreviousBusinessDay(masTime, 2, 'yyyy-MM-dd');
        const todayTime = dateToDateFormat(new Date(),'yyyy-MM-dd') ;

        return todayTime<limitDay;

        // console.log(limitDay, todayTime);
        // const diff = masTime - todayTime;
        //
        // const diffDay = Math.floor(diff / (1000*60*60*24));
        // console.log(diffDay);
        //
        // if(diffDay <= 2){
        //   return false;
        // }
        // return true;
      }

    }

    const clickTrnctMstSn = (item) => {
      search.trnctMstSn = item.trnctMstSn
      toggles.label = item.trnctNm;

      toggles.trnctOpen = false;
    }

    const clickFclt = () => {
      search.fcltDivCdDcd = '2094001';
      toggles.label = '일산 운동장';
      toggles.fcltOpen = false;
    }

    const initSearch = () => {
      toggles.label = '시설물 선택';
      schedule.value.startDt = '';
      schedule.value.endDt = '';


      kbDatePicker.value.initDate()
    }    
    const changeTrnctDt = (value) =>{
     
      updateTrnctAply.rsvtAplyDd = value.startDt?.ymdformat;
      if(updateTrnctAply.rsvtAplyDd) getTrnctOperYmdCheck(updateTrnctAply);
     //debugger // eslint-disable-line
      
    }

    const selectGrp = (item) => {
      updateFcltRsvtAply.grpDivCdDcd = item.cd
      updateFcltRsvtAply.grpDivNm = item.cdNm;
      toggles.grpToggle = false;

      updateFcltRsvtAply.grpNm = null;

      //당행동호회를 클릭시
      if (item.cd == '2095004') {
        //
        toggles.grpOrClub = false;

      } else {
        toggles.grpOrClub = true;
      }
    }

    const selectClub = (item) => {
      toggles.clubToggle = false;
      updateFcltRsvtAply.grpNm = item.label;
    }    

    const openGrpToggle = () => {
      if (toggles.grpToggle === true) {
        toggles.grpToggle = false;
      } else {
        toggles.grpToggle = true;
      }
    }

    return {
      updateFcltRsvtAply,
      getFcltAply,
      openCancelPop,
      cancelTrnctAply,
      closePop,
      clearParams,
      setUpdateTrnctAply,
      getTrnctOperYmdCheck,
      selectUsePeriod,
      usePeridOption,
      toggles,
      openPeriod,
      getDayOfWeek,
      updateTrnctAply,
      data,
      detailTrcntAply,
      reserveVenue,
      showVenues,
      showCalendar,
      filterVenue,
      filterDate,
      getTrcntAply,
      items,
      paging,
      pagingFunc,
      paging2,
      items2,
      pagingFunc2,
      getSportTime,
      detailFcltRsvtAply,
      cancelFcltRsvtAplyPop,
      setUpdateFcltRsvtAply,
      checkThreeDay,
      getTrnctMstList,
      trnctMstList,
      clickTrnctMstSn,
      search,
      schedule,
      initSearch,
      kbDatePicker,
      clickFclt,
      changeTrnctDt,
      userSearchParam,
      searchUser,
      fnBeforeUpdate,
      selectGrp,
      selectClub,
      codeList,
      openGrpToggle,
      openClubToggle,
      clubList,
      alertThreeday,
    }
  }
};
</script>

<style scoped>

</style>
