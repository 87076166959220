<template>
  <button type="button" class="kb-btn kb-btn-outline rounded-lg dropdown-btn" @click="openCal">
    <span class="text">입실 {{ startDt ? ymdStrToDateFormat(startDt.ymd, 'yyyy.MM.dd') : `날짜 선택` }}</span>
  </button>
  <!-- begin:popup-container -->
  <div class="popup-container" :class="[isOpen ? 'is-active' : 'is-inactive']">
    <!-- popup -->
    <div class="popup" id="popup-facility-calendar">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <form action="">
          <!-- popup-inner > popup-content -->
          <div class="popup-content">

            <div class="calendar">
              <div class="calendar-top">
                <h2 class="title">{{ currentYearMonth }}</h2>
                <div class="actions">
                  <button class="kb-btn-month kb-btn-month-prev" type="button" v-if="data.isDayShow">
                    <button type="button" class="kb-btn-datepicker-nav kb-btn-datepicker-prev" @click="changeMonth(false)"><i class="icon-prev"></i></button>
                    <span class="text" @click="changeMonth(false)">{{ prevYearMonth }}</span>
                  </button>
                  <button class="kb-btn-month kb-btn-month-prev" type="button" v-else>
                    <i class="text-warning mdi mdi-spin mdi-loading mdi-24px"></i>
                    <span class="text">{{ prevYearMonth }}</span>
                  </button>
                  <button class="kb-btn-month kb-btn-month-next" type="button" v-if="data.isDayShow">
                    <span class="text" @click="changeMonth(true)">{{ nextYearMonth }}</span>
                    <button type="button" class="kb-btn-datepicker-nav kb-btn-datepicker-next" @click="changeMonth(true)"><i class="icon-next"></i></button>
                  </button>
                  <button class="kb-btn-month kb-btn-month-next" type="button" v-else>
                    <span class="text">{{ nextYearMonth }}</span>
                    <i class="text-warning mdi mdi-spin mdi-loading mdi-24px"></i>
                  </button>
                </div>
              </div>
              <div class="calendar-body">
                <table class="calendar-table">
                  <thead>
                  <tr>
                    <th scope="col"><span class="day-header">일</span></th>
                    <th scope="col"><span class="day-header">월</span></th>
                    <th scope="col"><span class="day-header">화</span></th>
                    <th scope="col"><span class="day-header">수</span></th>
                    <th scope="col"><span class="day-header">목</span></th>
                    <th scope="col"><span class="day-header">금</span></th>
                    <th scope="col"><span class="day-header">토</span></th>
                  </tr>
                  </thead>
                  <tbody v-if="data.isDayShow">
                  <tr v-for="(week, idx) in currentWeeks" :key="idx">
                    <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                      <template v-if="day.month === currentMonth">

                        <template v-if="data.isClick">
                          <button v-if="roomTy != 'LTR'" type="button" class="day" :class="{ 'day-active' : getDayActive(day), 'day-disabled' : isPastDay(day) || getCalendarText(day) == '미운영' || getCalendarText(day) == '신청불가' || getCalendarText(day) == '마감', 'day-red' : isWeekend(day) }"  @click="handleSelectDate(day)" :disabled="isPastDay(day) || getCalendarText(day) == '미운영' || getCalendarText(day) == '신청불가' || getCalendarText(day) == '마감'">
                            <span class="day-text" :class="{ 'text-red' : isWeekend(day) }">{{ day.day }}</span>
                            <span v-if="isPastDay(day)" class="resv-text">{{ getCalendarText(day) }}</span>
                            <span v-else class="resv-text">{{  getCalendarText(day) }}</span>
                          </button>
                          <button v-if="roomTy == 'LTR'" type="button" class="day" :class="{ 'day-active' : getDayActive(day), 'day-disabled' : isDisabledLTR(day) || getCalendarText(day) == '미운영' || getCalendarText(day) == '신청불가' || getCalendarText(day) == '마감', 'day-red' : isWeekend(day) }"  @click="handleSelectDate(day)" :disabled="isDisabledLTR(day) || getCalendarText(day) == '미운영' || getCalendarText(day) == '신청불가' || getCalendarText(day) == '마감'">
                            <span class="day-text" :class="{ 'text-red' : isWeekend(day) }">{{ day.day }}</span>
                            <span v-if="isDisabledLTR(day)" class="resv-text">신청 불가</span>
                            <span v-else class="resv-text">{{  getCalendarText(day) }}</span>
                          </button>
                        </template>
                        <template v-else>
                          <span type="button" class="day day-disabled">
                            <span class="day-text" :class="{ 'text-red' : isWeekend(day) }">{{ day.day }}</span>
                            <span class="resv-text">조회중</span>
                          </span>

                        </template>

                      </template>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                    <tr v-for="(week, idx) in currentWeeks" :key="idx">
                      <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                        <template v-if="day.month === currentMonth">
                          <span class="day day-disabled">
                            <span class="day-text" :class="{ 'text-red' : isWeekend(day) }">{{ day.day }}</span>
                            <span class="resv-text">조회중</span>
                          </span>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="calendar-bottom">
                <button @click="isOpen = false" type="button" class="kb-btn kb-btn-primary kb-btn-calendar">
                  <span class="text">확인</span>
                </button>
              </div>
            </div>

          </div>
        </form>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button @click="isOpen = false" type="button" class="kb-btn-popup-close"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- end:popup-container -->
</template>

<script>
import {computed, reactive, ref, watch, onMounted} from 'vue';
import {dateToDateFormat, getCalendar, lengthCheck, ymdStrToDate, ymdStrToDateFormat} from '@/assets/js/util';
import {ACT_GET_TRNCT_OPER_YMD_CALENDER_CHECK} from "@/store/modules/trnct/trnct";
import {useStore} from "vuex";
import {useAlert} from "@/assets/js/modules/common/alert";
import {holyDay} from '@/assets/js/holy-day';

export default {
  name: 'KbCalender',
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {
          startDt: ''
        };
      },
    },
    trnctMstSn : {
      type: String,
      required : true
    },
    roomTy : {
      type: String,
      required : true
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const store = useStore();
    const {showMessage} = useAlert();

    const currentDate = new Date();


    // 현재 연월일 데이트 피커 데이터
    const currentYear = ref(currentDate.getFullYear());
    const currentMonth = ref(currentDate.getMonth() + 1);
    // const currentDay = ref(currentDate.getDate());

    const currentYearMonthOperYmd = ref(null);
    //여기서 디비에서 한달치 운영데이터를 가져 와야 한다.

    const data = reactive({
      isDayShow : false,
      isClick : false
    });



    // 오늘 연 월 일
    const today = reactive({
      year: currentDate.getFullYear(),
      month: (currentDate.getMonth() + 1),
      day: currentDate.getDate(),
    });

    // 이번주 데이터
    const currentWeeks = computed(() => getCalendar(currentYear.value, currentMonth.value));

    // Datepicker range 일때 두번째 달력 뿌릴 데이터
    // 다음 달이 내년일 경우 연도+1 아니면 이번년도
    const getNextYear = computed(() => {
      if (currentMonth.value === 12) {
        return currentYear.value + 1;
      } else {
        return currentYear.value;
      }
    });

    // 다음달이 내년일 경우 1월 아니면 다음달
    const getNextMonth = computed(() => {
      if (currentMonth.value === 12) {
        return 1;
      } else {
        return currentMonth.value + 1;
      }
    });

    const nextYear = ref(getNextYear.value);
    const nextMonth = ref(getNextMonth.value);

    // 2번째 달력의 주 정보
    const nextWeeks = computed(() => getCalendar(getNextYear.value, getNextMonth.value));

    const isOpen = ref(false);
    // const isLoading = ref(false);
    //isOpen = !isOpen

    const openCal = () =>{
      if(props.roomTy == null) {
        showMessage('먼저 방 유형을 선택 해 주세요');
        return;
      }else{
        isOpen.value = true;
      }
    };
    const todayYmd = `${today.year}${('0' + today.month).slice(-2)}${('0' + today.day).slice(-2)}`;

    const startDt = ref('');
    const endDt = ref('');

    const currentYearMonth = computed(() => {
      return `${currentYear.value}년 ${currentMonth.value}월`;
      /*if (currentMonth.value === 12) {
        return `${currentYear.value + 1}년 1월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value}월`;
      }*/
    });

    const nextYearMonth = computed(() => {
      if (currentMonth.value === 12) {
        return `${currentYear.value + 1}년 1월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value + 1}월`;
      }
    });

    const prevYearMonth = computed(() => {
      if (currentMonth.value === 1) {
        return `${currentYear.value - 1}년 12월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value - 1}월`;
      }
    });

    const session = computed(() => store.state.auth.session);

    const imsiUserList = ref(['1533872']);
    const monthDiff = () => {

      let year = new Date().getFullYear() * 12;
      let month = new Date().getMonth() + 1;
      let year1;
      let month1;
      if (currentMonth.value === 12) {
        year1 = ( currentYear.value + 1 ) * 12;
        month1 = 1
      }else{
        year1 = currentYear.value * 12
        month1 = currentMonth.value + 1
      }
      let diff = ( year1 + month1 ) - ( year + month )

      if(diff >= 3){
        if(imsiUserList.value.includes(store.state.auth.session.lrnerId)){
          return true;
        }
        return false;
      }
      return true;
    }

    // 다음, 이전 연 월 버튼 기능
    const changeMonth = async (isNext) => {
      if (isNext) {
        if(monthDiff()){
          if (currentMonth.value === 12) {
            currentYear.value++;
            currentMonth.value = 1;
          } else {
            currentMonth.value++;
          }

          if (nextMonth.value === 12) {
            nextYear.value++;
            nextMonth.value = 1;
          } else {
            nextMonth.value++;
          }
        }else{
          //do nothing
          showMessage('신청기간이 아닙니다. 연수원관리자에게 문의하세요.');
          return;

        }


      } else {
        if (currentMonth.value === 1) {
          currentYear.value--;
          currentMonth.value = 12;
        } else {
          currentMonth.value--;
        }

        if (nextMonth.value === 1) {
          nextYear.value--;
          nextMonth.value = 12;
        } else {
          nextMonth.value--;
        }
      }

      await getCurrentYearMonthOperYmd();
    };

    const init = (startDt) => {
       if(startDt){
      console.log("init date selected" ,startDt);
      //   currentDate = new Date(startDt.year, startDt.month-1, startDt.day );
         currentYear.value = startDt.year;
         currentMonth.value = startDt.month;
       }else{
         currentYear.value = currentDate.getFullYear();
         // currentMonth.value = currentDate.getMonth() + 1;
         //기본 표시는 익월
         currentMonth.value = currentDate.getMonth() + 2;
      }
      nextYear.value = getNextYear.value;
      nextMonth.value = getNextMonth.value;
    };

    const isActiveCheckOutDate = ref(false);

    const handleSelectDate = (date) => {

      if(data.isClick){
        startDt.value = date;
      }

    };

    const isPastDay = (date) => {
      if (currentDate < ymdStrToDate(date.ymd)) return false;
      return true;
    };


    const isDisabledLTR = (date) => {
      if (currentDate < ymdStrToDate(date.ymd)){
        const week = ['일', '월', '화', '수', '목', '금', '토'];
        const dayOfWeek = week[new Date(date.ymdformat).getDay()];
        if(dayOfWeek === '금' || dayOfWeek === '토' || dayOfWeek === '일') return true;
        return false;
      }else{
        return true;
      }




    };

    const isWeekend = (date) => {
      const week = ['일', '월', '화', '수', '목', '금', '토'];

      const dayOfWeek = week[new Date(date.ymdformat).getDay()];

      // console.log("check holi", date.ymd, (dayOfWeek === '토' || dayOfWeek === '일'),  holyDay[date.ymd] );
      if(dayOfWeek === '토' || dayOfWeek === '일') return true;


      return holyDay[date.ymd];
      // return false;
    }

    watch(() => isOpen.value, () => {
      console.log('isopen', props.roomTy, props.modelValue?.startDt)

      // 열고 닫을때 오늘 기준 월 보여주기 위해 init()
      if (isOpen.value) {
        init(props.modelValue?.startDt);
        getCurrentYearMonthOperYmd();
        if (startDt.value) {
          isActiveCheckOutDate.value = true;
        }
      } else {
        isActiveCheckOutDate.value = false;
      }
    });

    watch(() => [startDt.value], () => {
      if (startDt.value) {
        isActiveCheckOutDate.value = true;
        isOpen.value = false;
      } else {
        isActiveCheckOutDate.value = false;
      }

      emit('update:modelValue',{ startDt: startDt.value });
    });

    watch(() => props.modelValue?.startDt, () => {
      console.log(":####props.modelValue.startDt################",props.modelValue?.startDt);
      
      if(props.modelValue?.startDt==''){
          startDt.value = '';  
       // init();
        //getCurrentYearMonthOperYmd();      
      }
    });

    watch(() => props.modelValue, () => {
      console.log(":####props.modelValue################",props.modelValue);      
      if (props.modelValue) {
        if (!props.modelValue.startDt) {
         /* let year = props.modelValue.startDt.ymdformat.split('-')[0];
          let month = props.modelValue.startDt.ymdformat.split('-')[1];
          let day = props.modelValue.startDt.ymdformat.split('-')[2];

          const obj = {
            day : Number(day),
            month : Number(month),
            year : Number(year),
            ymd : year + month + day,
            ymdformat : year + '-' + month + '-' + day
          }

          startDt.value = obj;*/
          return;
        }
        if (!props.modelValue.endDt) {
          endDt.value = '';
        }
      }
    });

    const getCurrentYearMonthOperYmd = async () => {
      currentYearMonthOperYmd.value = null;
      data.isDayShow = false;
      data.isClick = false;

      console.log(currentYear.value+'-'+currentMonth.value);
      await store.dispatch(`trnct/${ACT_GET_TRNCT_OPER_YMD_CALENDER_CHECK}`,{
        trnctMstSn : props.trnctMstSn, //얘도 앞에서 받아와야 한다
        rsvtAplyDd : currentYear.value+'-'+ ( currentMonth.value <= 9 ? "0"+currentMonth.value : currentMonth.value ),
        prsnBrcYn : 'Y', //개인으로 하드 코딩
        roomTy : props.roomTy //룸타임 앞에서 받아 와야 한다/
      }).then(res => {
        if(lengthCheck(res)){
          console.log('getTrnctOperYmdCheck...',res);
          currentYearMonthOperYmd.value = res.items[0];
          data.isDayShow = true;
          data.isClick = true;
          /*usePeridOption.value = [];
          for(let i = 0 ; i < res.items.length ;i++){
            usePeridOption.value.push(res.items[i]);
          }*/

        }
      }).catch((e) => {console.error(e);})
    }

    const getCalendarText = (date) =>{
      return currentYearMonthOperYmd.value[date.ymdformat];
    }

    const getDayActive = (date) => {
      if(startDt.value.ymdformat === date.ymdformat)
        return true
      else
        return false
    }

    onMounted(()=>{
      console.log("init props", props.modelValue.startDt);
    });

    return {
      ymdStrToDate,
      dateToDateFormat,
      ymdStrToDateFormat,

      today,
      isOpen,

      currentYear,
      currentMonth,
      // currentDay,
      currentWeeks,

      nextYear,
      nextMonth,
      nextWeeks,

      todayYmd,
      currentYearMonth,
      nextYearMonth,
      prevYearMonth,
      changeMonth,

      handleSelectDate,
      isActiveCheckOutDate,

      isPastDay,

      startDt,
      endDt,
      isWeekend,
      getCurrentYearMonthOperYmd,
      currentYearMonthOperYmd,
      getCalendarText,
      data,
      getDayActive,
      session,
      isDisabledLTR,
      openCal
    };
  },
};
</script>
